<template>
  <div class="html-editor rounded-md">
    <editor-menu-bar v-if="editor && !disabled" v-slot="{ commands, isActive, focused }" :editor="editor">
      <div class="menubar" :class="{ 'is-focused': focused }">
        <button class="button is-text is-small ml-0" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
          <icon name="bold" />
        </button>

        <button class="button is-text is-small" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <icon name="italic" />
        </button>

        <button
          class="button is-text is-small"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <icon name="underline" />
        </button>

        <button
          class="button is-text is-small"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <icon name="paragraph" />
        </button>

        <button
          class="button is-text is-small"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <icon name="ul" />
        </button>

        <button class="button is-text is-small" @click="commands.undo">
          <icon name="undo" />
        </button>

        <button class="button is-text is-small" @click="commands.redo">
          <icon name="redo" />
        </button>
      </div>
    </editor-menu-bar>
    <editor-content :editor="editor" @focus="emitFocus()" />
  </div>
</template>

<script>
// https://github.com/ueberdosis/tiptap/blob/v1/examples/Components/Routes/Basic/index.vue
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder, BulletList, ListItem } from 'tiptap-extensions'

export default {
  name: 'HtmlEditor',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    id: {
      type: String,
      default: 'htmlEditor',
    },
    value: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: 'Escreva aqui...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editorClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      if (this.editor.getHTML() === value) return
      this.editor.setContent(this.value, false)
    },
  },

  mounted() {
    const classText = `editor ${this.editorClass}`
    const self = this
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: classText,
        },
      },
      editable: !self.disabled,
      injectCSS: false,
      content: self.value,
      extensions: [
        new Link(),
        new Bold(),
        new Italic(),
        new Underline(),
        new History(),
        new BulletList(),
        new ListItem(),
        new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: self.placeholderText,
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
      ],
      onUpdate: ({ getHTML }) => {
        this.$emit('input', getHTML())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    emitFocus() {
      this.$emit('focus', this.id)
    },
  },
}
</script>

<style>
.html-editor .drop-cursor {
  border-radius: 3px;
  overflow: hidden;
}

.html-editor .menubar button:first-child {
  margin: 0;
}

.html-editor .ProseMirror .h1 .content {
  font-size: 2em;
}

.html-editor .ProseMirror .h2 .content {
  font-size: 1.5em;
}

.html-editor .ProseMirror .h3 .content {
  font-size: 1.2em;
}

.html-editor .ProseMirror.editor {
  background-color: transparent !important;
  @apply p-3;
}

.html-editor .ProseMirror.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  @apply text-gray-600;
}

.html-editor .ProseMirror.editor[contenteditable='false'] {
  @apply cursor-not-allowed text-gray-500;
}
</style>
